<template>
    <v-card flat>
        <v-card-text>
            <span class="d-flex flex-column align-center justify-center">
                <h3 v-text="'Nombre de sections : ' + form.sections.length"></h3>
                <h3 v-text="'Nombre de questions : ' + questionsAmount"></h3>
                <div class="my-3" style="width: 10%;">
                    <v-divider></v-divider>
                </div>
                <h3 v-text="'Nombre de questions obligatoires : ' + requiredQuestionsAmount"></h3>
                <h3 v-text="'Nombre de questions facultatives : ' + (questionsAmount - requiredQuestionsAmount)"></h3>
                <div class="my-3" style="width: 10%;">
                    <v-divider></v-divider>
                </div>
                <h3 v-text="'Nombre de réponses enregistrées : ' + responses.length"></h3>
            </span>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "StatsTab",
    props : ['responses', 'form'],
    computed : {
        questionsAmount() {
            let amount = 0
            this.form.sections.forEach(section => {
                amount += section.questions.length
            })
            return amount
        },
        requiredQuestionsAmount(){
            let amount = 0
            this.form.sections.forEach(section => {
                section.questions.forEach(question => {
                    if(question.required || question.type == 'slider') {
                        amount++
                    }
                })
            })
            return amount
        }
    }
}
</script>