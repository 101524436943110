<template>
    <v-card flat>
        <v-card-text>
            <v-simple-table class="mt-5">
                <tfoot>
                    <tr>
                        <th class="py-3">
                            <span class="d-flex align-center justify-space-between">
                                <v-btn icon x-large @click="previousQuestion">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <h1 class="text-center" v-text="form.sections[this.currentSectionIndex].name +' : '+currentQuestion.title"></h1>
                                <v-btn icon x-large @click="nextQuestion">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </span>
                        </th>
                    </tr>
                    <tr v-for="response in currentResponses" v-if="currentQuestion.type == 'short' || currentQuestion.type == 'long'">
                        <th class="py-3" v-if="response.trim() != ''">
                            <h3 v-text="response"></h3>
                        </th>
                    </tr>
                    <tr v-if="currentQuestion.type == 'listeMultiple'">
                        <th class="py-3" v-if="currentResponses.length > 0 ">
                            <VueApexCharts :options="barChartOptions" :series="currentChartSeries"></VueApexCharts>
                        </th>
                    </tr>
                    <tr v-else-if="currentQuestion.type == 'listeUnique'">
                        <th class="py-3" v-if="currentResponses.length > 0 ">
                            <VueApexCharts :options="barChartOptions" :series="currentChartSeries"></VueApexCharts>
                        </th>
                    </tr>
                </tfoot>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: "QuestionsTab",
    props : ['responses', 'form'],
    components: {
        VueApexCharts
    },
    data() {
        return {
            currentSectionIndex : 0,
            currentQuestionIndex : 0,
            barChartOptions: {
                chart: {
                    type: 'bar',
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    },
                },
                stroke: {
                    width: 0,
                },
                colors : ['#006533'],
                states: {
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    position: 'center',
                },
                responsive: [{
                    breakpoint: Infinity,
                    options: {
                        chart: {
                            height: '350',
                        },
                    }
                }]
            },
        }
    },
    mounted() {
        if(this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].type == 'slider'){
            this.nextQuestion()
        }
    },
    computed : {
        currentQuestion() {
            return this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex]
        },
        currentResponses() {
            let responses = []
            for(let response of this.responses){
                responses.push(response.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].answer)
            }
            return responses
        },
        currentChartSeries(){
            let data = []
            
            if(this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].type == 'listeMultiple'){
                for(let choice of this.currentQuestion.choices){
                    let count = 0
                    for(let response of this.responses){
                        if(response.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].answer.includes(choice)){
                            count++
                        }
                    }
                    data.push({x: choice, y: count})
                }
            }else if(this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].type == 'listeUnique'){
                for(let choice of this.currentQuestion.choices){
                    let count = 0
                    for(let response of this.responses){
                        if(response.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].answer == choice){
                            count++
                        }
                    }
                    data.push({x: choice, y: count})
                }
            }
            return [{data: data}]
        }
    },
    methods : {
        nextQuestion() {
            if(this.currentQuestionIndex < this.form.sections[this.currentSectionIndex].questions.length - 1) {
                this.currentQuestionIndex++
            } else {
                if(this.currentSectionIndex < this.form.sections.length - 1) {
                    this.currentSectionIndex++
                    this.currentQuestionIndex = 0
                }else{
                    this.currentSectionIndex = 0
                    this.currentQuestionIndex = 0
                }
            }
            if(this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].type == 'slider'){
                this.nextQuestion()
            }
        },
        previousQuestion() {
            if(this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--
            } else {
                if(this.currentSectionIndex > 0) {
                    this.currentSectionIndex--
                    this.currentQuestionIndex = this.form.sections[this.currentSectionIndex].questions.length - 1
                }else{
                    this.currentSectionIndex = this.form.sections.length - 1
                    this.currentQuestionIndex = this.form.sections[this.currentSectionIndex].questions.length - 1
                }
            }
            if(this.form.sections[this.currentSectionIndex].questions[this.currentQuestionIndex].type == 'slider'){
                this.previousQuestion()
            }
        }
    }
}
</script>