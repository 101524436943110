<template>
    <v-card flat>
        <v-card-text>
            <v-simple-table class="mt-5">
                <tfoot>
                    <tr>
                        <th class="py-3">
                            <span class="d-flex align-center justify-space-between">
                                <v-btn icon x-large @click="previousResponse">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <h1 class="text-center" v-text="'Réponse n°'+(currentResponseIndex +1)"></h1>
                                <v-btn icon x-large @click="nextResponse">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </span>
                        </th>
                    </tr>
                </tfoot>
            </v-simple-table>
            <v-simple-table v-if="currentResponse"  v-for="section,index in currentResponse.sections">
                <tfoot>
                    <tr class="primary">
                        <th colspan="2">
                            <h1 class="text-center white--text" v-text="section.name"></h1>
                        </th>
                    </tr>
                    <tr v-for="question, index in section.questions">
                        <th :rowspan="section.questions.length" v-if="index == 0" style="width: 1rem;">
                        <v-divider vertical></v-divider>
                        </th>
                        <th class="py-3" v-if="question.type =='slider'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span class="accent--text"> *</span>
                            </h3>
                            <span class="px-5 d-flex flex-row align-center justify-center">
                                <v-slider class="pr-5 pt-6 pb-4" min="0" max="4" thumb-label ticks="always" tick-size="3" hide-details :value="question.answer" disabled>
                                    <template v-slot:thumb-label>
                                        <v-img height="30px" width="30px" :src="require(`@/assets/images/smiley/smiley ${smileyOrder[question.answer]}.svg`)"></v-img>
                                    </template>
                                </v-slider>
                                <span>
                                    <v-img height="50px" width="50px" :src="require(`@/assets/images/smiley/smiley ${smileyOrder[question.answer]}.svg`)"></v-img>
                                </span>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='short'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="px-5 d-flex flex-row align-center justify-center">
                                <v-text-field label="Réponse" :value="question.answer" disabled></v-text-field>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='long'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="px-5 d-flex flex-row align-center justify-center">
                                <v-textarea rows="3" label="Réponse" :value="question.answer" disabled></v-textarea>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='listeUnique'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="px-5 d-flex flex-row align-center justify-center">
                                <v-select :items="question.choices" :value="question.answer" disabled></v-select>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='listeMultiple'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="px-5 d-flex flex-row align-center justify-center">
                                <v-select :items="question.choices" :value="question.answer" multiple disabled></v-select>
                            </span>
                        </th>
                    </tr>
                </tfoot>
            </v-simple-table>
            <span class="mt-5 d-flex align-center justify-center">
                <v-btn color="accent" outlined @click="deleteResponse">
                    <v-icon>mdi-delete-outline</v-icon>
                    <span>Supprimer cette réponse</span>
                </v-btn>
            </span>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
    name: "IndividualTab",
    props : ['responses', 'form'],
    data() {
        return {
            smileyOrder: ['R', 'O', 'J', 'VJ', 'VV'],
            currentResponseIndex : 0,
        }
    },
    computed : {
        currentResponse() {
            return this.responses[this.currentResponseIndex]
        },
    },
    methods : {
        nextResponse() {
            if(this.currentResponseIndex < this.responses.length -1) {
                this.currentResponseIndex++
            }else {
                this.currentResponseIndex = 0
            }
        },
        previousResponse() {
            if(this.currentResponseIndex > 0) {
                this.currentResponseIndex--
            }else {
                this.currentResponseIndex = this.responses.length -1
            }
        },
        deleteResponse() {
            Swal.fire({
                title: 'Êtes-vous sûr ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.responses[this.currentResponseIndex].delete()
                    this.nextResponse()
                    this.$forceUpdate()
                }
            })
        }
    }
}
</script>