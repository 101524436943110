<template>
    <v-card flat>
        <v-card-text>
            <span class="d-flex flex-column align-center justify-center">
                <v-btn class="mt-5" color="primary" outlined @click="DownloadRawData">
                    Télécharger les données brutes
                </v-btn>
                <v-btn class="mt-5" color="indigo" outlined @click="generateReport">
                    Télécharger le rapport
                </v-btn>
            </span>
        </v-card-text>
    </v-card>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs"
import JSZip from "jszip"

export default {
    name: "ExportTab",
    props : ['responses', 'form'],
    methods : {
        generateReport(){
            window.location.href = window.location.origin + '/print/' + this.form.id
        },
        async DownloadRawData(){
            let zip = new JSZip()
            
            let data = []

            let sections = ['id']
            let header = ['id']
            let merges = [{s:{r:0,c:0},e:{r:1,c:0}}]                    

            let lastCol = 0
            let currentCol = 0
            for(let section of this.form.sections){
                lastCol = currentCol
                for(let question of section.questions){
                    sections.push(section.name)
                    header.push(question.title)
                    currentCol++
                }
                if(currentCol > lastCol + 1){
                    merges.push({s:{r:0,c:lastCol + 1},e:{r:0,c:currentCol}})
                }
            }
            data.push(sections)
            data.push(header)
            for(let [responseIndex, response] of this.responses.entries()){
                let row = [responseIndex]
                for(let section of response.sections){
                    for(let question of section.questions){
                        let answer = question.answer
                        if(answer == null || answer == undefined ){
                            answer = ""
                        }
                        else if(Array.isArray(answer)){
                            answer = answer.join("; ")
                        }
                        row.push(answer)
                    }
                }
                data.push(row)
            }

            let book = XLSX.utils.book_new()
            let sheet = XLSX.utils.aoa_to_sheet(data)

            sheet["!merges"] = merges

            XLSX.utils.book_append_sheet(book, sheet, 'testPage')
            let fileBuffer = XLSX.write(book, {outputType:"xlsx", type:'buffer'})

            zip.file("Data.xlsx", fileBuffer)
            let zipBlob = await zip.generateAsync({type:"blob"})

            const link = document.createElement('a')
            link.setAttribute('href', URL.createObjectURL(zipBlob))
            link.setAttribute('download', "data.zip")
            link.click()
            link.remove()
        }
    }
}
</script>